<script>
import {
  BCard,
  BFormCheckbox,
  BImg,
  BCardBody,
  BInputGroup,
  BCardText,
  BCardHeader,
  BFormTags,
} from "bootstrap-vue";

import Editor from "@tinymce/tinymce-vue";

import { required, minLength } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapState } from "vuex";
import draggable from "vuedraggable";

import "swiper/css/swiper.css";

import { stringToSlug } from "@/_helpers/_urlHelper";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    ButtonsActionsFooter,
    vSelect,
    BInputGroup,
    editor: Editor,
    BCard,
    BCardBody,
    BFormCheckbox,
    BCardHeader,
    BCardText,
    BFormTags,
    BImg,
    draggable,
    flatPickr,
  },
  validations: {
    item: {
      title: {
        required,
        minLength: minLength(5),
      },
      header: {
        required,
      },
      description: {
        required,
      },
      slug: {
        required,
        minLength: minLength(5),
      },
      areas: {
        required,
      },
      author: {
        required,
      },
    },
  },
  data() {
    return {
      contest: "",
      image_url: "",
      submited: false,
      preview: "",
      item: {
        header: "",
        title: "",
        image: "",
        author: [],
        areas: [],
        tags: [],
        description: "",
        slug: "",
        image_caption: "",
        contests: [],
        display_site: 0,
        display_blog_home: 0,
        // display_blog_highlights: 0,
        title_seo: "",
        title_description: "",
        created_at: "",
        updated_at: "",
      },
      uuid: "",
      optionsContests: [],
      optionsAreas: [],
      optionsAuthors: [],
    };
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.getData();
  },
  computed: {
    ...mapState("Area", ["searchAreas"]),
  },
  methods: {
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    selectContest(event) {
      if (event) {
        this.optionsContests = [];
        this.contest = [];

        this.item.contests.push({
          code: event.code,
          title: event.title,
        });
      }
    },
    removeAtContest(id) {
      this.item.contests.splice(id, 1);
    },
    getData() {
      this.$store.dispatch("Post/show", this.uuid).then((res) => {
        this.item = res.data;

        this.item.display_site = this.item.display_site ? true : false;
        this.item.display_blog_home = this.item.display_blog_home
          ? true
          : false;
        // this.item.display_blog_highlights = this.item.display_blog_highlights ? true : false;

        this.image_url = this.item.image_old
          ? this.item.image_old
          : this.item.image_url;

        this.item.areas = this.item.areas;

        let arrayTags = [];
        const tags = JSON.parse(this.item.tags);

        if (this.item.tags) {
          for (const tag of tags) {
            tag.name ? arrayTags.push(tag.name) : arrayTags.push(tag);
          }
        }

        this.item.tags = arrayTags;

        if (
          this.item.title_seo === null ||
          this.item.title_seo === "null" ||
          !this.item.title_seo
        ) {
          this.item.title_seo = this.item.title;
        }
      });
    },
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    async fetchAuthors(term) {
      if (term.length > 2) {
        this.$store.dispatch("Author/search", term).then((res) => {
          this.optionsAuthors = res;
        });
      }
    },
    async fetchContests(term) {
      this.optionsContests = [];
      if (term.length > 2) {
        this.$store.dispatch("Contest/search", { term: term }).then((res) => {
          this.optionsContests = res;
        });
      }
    },
    submitUpdate() {
      this.$v.item.$touch();
      this.submited = true;

      if (!this.$v.item.$error) {
        const formData = new FormData();

        formData.append("header", this.item.header);
        formData.append("image", this.item.image);
        formData.append("title", this.item.title);
        formData.append("description", this.item.description);
        formData.append("title_seo", this.item.title_seo);
        formData.append("description_seo", this.item.description_seo ?? "");
        formData.append("slug", this.item.slug);

        formData.append("image_caption", this.item.image_caption);

        formData.append("areas", JSON.stringify(this.item.areas));

        formData.append(
          "author",
          this.item.author ? this.item.author.code : ""
        );

        formData.append("contests", JSON.stringify(this.item.contests));

        formData.append("tags", JSON.stringify(this.item.tags) ?? "");

        formData.append("created_at", this.item.created_at);
        formData.append("updated_at", this.item.updated_at);

        formData.append(
          "display_blog_home",
          this.item.display_blog_home ? 1 : 0
        );

        formData.append("display_site", this.item.display_site ? 1 : 0);

        this.$store
          .dispatch("Post/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "blog-posts-list" });
          })
          .catch(() => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  watch: {
    "item.slug": {
      handler() {
        if (this.item.slug) {
          this.item.slug = stringToSlug(this.item.slug);
        }
      },
    },
  },
  directives: {
    Ripple,
  },
};
</script>
<template>
  <div>
    <form @submit.prevent="submitUpdate" enctype="multipart/form-data">
      <div class="form-row">
        <div class="col-md-8">
          <b-card header="Informações" class="mb-1">
            <div class="form-row">
              <div class="col-md-12">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Título
                </label>
                <div class="form-group mb-0">
                  <input
                    class="form-control"
                    v-model="item.title"
                    :class="{ 'is-invalid': $v.item.title.$error }"
                  />
                </div>
              </div>
            </div>
          </b-card>

          <b-card class="mb-1">
            <div class="form-row">
              <div class="col-12">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Subtítulo -
                  <small
                    >Exibido antes da imagem de destaque e abaixo do
                    título</small
                  >
                </label>
                <div
                  class="form-group mb-0"
                  :class="{ 'is-invalid': $v.item.header.$error }"
                >
                  <editor
                    class="description-editor-1"
                    api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                    v-model="item.header"
                    :init="
                      this.optionEditor({
                        height: 300,
                        selector: 'description-editor-1',
                      })
                    "
                  />
                </div>
              </div>
            </div>
          </b-card>

          <b-card class="mb-1">
            <div class="form-row">
              <div class="col-12">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Descrição - <small>Exibido após a imagem de destaque</small>
                </label>
                <div
                  class="form-group mb-0"
                  :class="{ 'is-invalid': $v.item.description.$error }"
                >
                  <editor
                    class="description-editor-2"
                    api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                    v-model="item.description"
                    :init="
                      this.optionEditor({
                        height: 600,
                        selector: 'description-editor-2',
                      })
                    "
                  />
                </div>
              </div>
            </div>
          </b-card>

          <b-card
            header="Concursos relacionados"
            header-class="mb-1"
            class="mb-1"
          >
            <div class="form-row">
              <div class="col-md-12">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="contest"
                  placeholder="Digite o título do concurso"
                  :options="optionsContests"
                  @search="fetchContests"
                  @input="selectContest"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <draggable
              tag="ul"
              :list="this.item.contests"
              class="list-group mt-1"
              handle=".handle"
            >
              <li
                v-for="(element, index) in this.item.contests"
                :key="index"
                class="list-group-item"
              >
                <div class="form-row">
                  <div class="col-md-11" style="line-height: 1.8">
                    <feather-icon icon="MoveIcon" class="handle" size="15" />
                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>
                  </div>
                  <div class="col-md-1">
                    <button
                      type="button"
                      style="padding: 4px 9px !important"
                      class="btn btn-block btn-sm text-center btn-danger text-danger float-right"
                      @click="removeAtContest(index)"
                    >
                      <feather-icon icon="XCircleIcon" size="16" />
                    </button>
                  </div>
                </div>
              </li>
            </draggable>
          </b-card>

          <b-card class="mb-1" header="Sites de buscas">
            <b-card-text>
              <p class="mb-2">
                Caso queira personalizar o título, descrição e url para
                mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar
                SEO do site" e preencha os campos abaixo.
              </p>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Título SEO</label>
                    <input
                      v-model="item.title_seo"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">URL</label>
                    <b-input-group
                      :prepend="`/noticia/`"
                      class="input-group-merge"
                    >
                      <input
                        class="form-control text-primary pl-1"
                        v-model="item.slug"
                      />
                    </b-input-group>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group mb-0">
                    <label for="">Descrição para SEO</label>
                    <textarea
                      v-model="item.description_seo"
                      maxlength="320"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card class="mb-1" header="Imagem de destaque">
            <input
              id="image"
              type="file"
              accept="image/*"
              class="form-control-file hidden"
              @change="previewImage"
            />
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label
                    for="image"
                    class="d-block text-center"
                    style="
                      border: 1px solid rgb(222 222 222);
                      border-radius: 4px;
                      border-style: dashed;
                      cursor: pointer;
                      font-size: 1.1rem;
                    "
                  >
                    <div v-if="!preview && !image_url" class="pt-5 py-4">
                      <feather-icon size="19" icon="ImageIcon" />
                      Selecionar imagem
                    </div>
                    <template v-if="preview">
                      <b-img
                        for="image"
                        :src="preview"
                        style="width: 100%"
                        fluid
                      />
                    </template>
                    <template v-else-if="!preview && image_url">
                      <b-img
                        for="image"
                        :src="image_url"
                        style="width: 100%"
                        fluid
                      />
                    </template>
                  </label>
                </div>
              </div>

              <div class="col-md-12">
                <label> Legenda </label>
                <input
                  type="text"
                  v-model="item.image_caption"
                  class="form-control"
                />
              </div>
            </div>
          </b-card>

          <b-card header="Publicação" class="mb-1">
            <b-card-text>
              <div class="row">
                <div class="col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Criado
                  </label>
                  <div class="form-group">
                    <flat-pickr
                      v-model="item.created_at"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        altInput: true,
                        dateFormat: 'Y-m-d H:m',
                        altFormat: 'd/m/Y H:m',
                        locale: 'pt',
                      }"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <label for=""> Atualizado </label>
                  <div class="form-group mb-0">
                    <flat-pickr
                      v-model="item.updated_at"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        altInput: true,
                        dateFormat: 'Y-m-d H:m',
                        altFormat: 'd/m/Y H:m',
                        locale: 'pt',
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card header="Opções" class="mb-1">
            <b-card-text>
              <div class="row">
                <div class="col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Jornalista
                  </label>
                  <div class="form-group mb-2">
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.author"
                      placeholder="Digite o nome"
                      :options="optionsAuthors"
                      @search="fetchAuthors"
                      :class="{ 'is-invalid': $v.item.author.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Área(s)
                  </label>
                  <div class="form-group mb-2">
                    <v-select
                      multiple
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.areas"
                      placeholder="Digite o título"
                      :options="optionsAreas"
                      @search="fetchAreas"
                      :class="{ 'is-invalid': $v.item.areas.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b-form-checkbox
                      v-model="item.display_site"
                      class="custom-control-primary"
                    >
                      Exibir na pág. inicial do <u>site</u>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b-form-checkbox
                      v-model="item.display_blog_home"
                      class="custom-control-primary"
                    >
                      Exibir na pág. inicial do <u>blog</u>
                    </b-form-checkbox>
                  </div>
                </div>
                <!-- <div class="col-md-12">
                  <div class="form-group mb-0">
                    <b-form-checkbox
                      v-model="item.display_blog_highlights"
                      class="custom-control-primary"
                    >
                      Exibir na sessão destaques do <u>blog</u>
                    </b-form-checkbox>
                  </div>
                </div> -->
              </div>
            </b-card-text>
          </b-card>

          <b-card header="Tags" class="mb-1">
            <b-card-text>
              <div class="row">
                <div class="col-md-12 col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Tag(s)
                  </label>
                  <div class="form-group mb-0">
                    <b-form-tags
                      v-model="item.tags"
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <div class="form-row justify-content-right">
            <div class="col-md-12 col-12">
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                text="Salvar"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="scss"></style>
