var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { enctype: "multipart/form-data" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitUpdate.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "col-md-8" },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Informações" } },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Título "),
                      ]),
                      _c("div", { staticClass: "form-group mb-0" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.title,
                              expression: "item.title",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.item.title.$error },
                          domProps: { value: _vm.item.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "title", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("b-card", { staticClass: "mb-1" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Subtítulo - "),
                      _c("small", [
                        _vm._v(
                          "Exibido antes da imagem de destaque e abaixo do título"
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-group mb-0",
                        class: { "is-invalid": _vm.$v.item.header.$error },
                      },
                      [
                        _c("editor", {
                          staticClass: "description-editor-1",
                          attrs: {
                            "api-key":
                              "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                            init: this.optionEditor({
                              height: 300,
                              selector: "description-editor-1",
                            }),
                          },
                          model: {
                            value: _vm.item.header,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "header", $$v)
                            },
                            expression: "item.header",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b-card", { staticClass: "mb-1" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Descrição - "),
                      _c("small", [
                        _vm._v("Exibido após a imagem de destaque"),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-group mb-0",
                        class: { "is-invalid": _vm.$v.item.description.$error },
                      },
                      [
                        _c("editor", {
                          staticClass: "description-editor-2",
                          attrs: {
                            "api-key":
                              "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                            init: this.optionEditor({
                              height: 600,
                              selector: "description-editor-2",
                            }),
                          },
                          model: {
                            value: _vm.item.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "description", $$v)
                            },
                            expression: "item.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "b-card",
                {
                  staticClass: "mb-1",
                  attrs: {
                    header: "Concursos relacionados",
                    "header-class": "mb-1",
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o título do concurso",
                              options: _vm.optionsContests,
                            },
                            on: {
                              search: _vm.fetchContests,
                              input: _vm.selectContest,
                            },
                            model: {
                              value: _vm.contest,
                              callback: function ($$v) {
                                _vm.contest = $$v
                              },
                              expression: "contest",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "draggable",
                    {
                      staticClass: "list-group mt-1",
                      attrs: {
                        tag: "ul",
                        list: this.item.contests,
                        handle: ".handle",
                      },
                    },
                    _vm._l(this.item.contests, function (element, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "list-group-item" },
                        [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-11",
                                staticStyle: { "line-height": "1.8" },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "handle",
                                  attrs: { icon: "MoveIcon", size: "15" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "pl-1 text-not-selected" },
                                  [_vm._v(_vm._s(element.title) + " ")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-1" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-block btn-sm text-center btn-danger text-danger float-right",
                                  staticStyle: {
                                    padding: "4px 9px !important",
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAtContest(index)
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "XCircleIcon", size: "16" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Sites de buscas" } },
                [
                  _c("b-card-text", [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        ' Caso queira personalizar o título, descrição e url para mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar SEO do site" e preencha os campos abaixo. '
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Título SEO"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.title_seo,
                                expression: "item.title_seo",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.title_seo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "title_seo",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("URL"),
                            ]),
                            _c(
                              "b-input-group",
                              {
                                staticClass: "input-group-merge",
                                attrs: { prepend: "/noticia/" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.slug,
                                      expression: "item.slug",
                                    },
                                  ],
                                  staticClass: "form-control text-primary pl-1",
                                  domProps: { value: _vm.item.slug },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "slug",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Descrição para SEO"),
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.description_seo,
                                expression: "item.description_seo",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { maxlength: "320" },
                            domProps: { value: _vm.item.description_seo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "description_seo",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-1",
                  attrs: { header: "Imagem de destaque" },
                },
                [
                  _c("input", {
                    staticClass: "form-control-file hidden",
                    attrs: { id: "image", type: "file", accept: "image/*" },
                    on: { change: _vm.previewImage },
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "d-block text-center",
                            staticStyle: {
                              border: "1px solid rgb(222 222 222)",
                              "border-radius": "4px",
                              "border-style": "dashed",
                              cursor: "pointer",
                              "font-size": "1.1rem",
                            },
                            attrs: { for: "image" },
                          },
                          [
                            !_vm.preview && !_vm.image_url
                              ? _c(
                                  "div",
                                  { staticClass: "pt-5 py-4" },
                                  [
                                    _c("feather-icon", {
                                      attrs: { size: "19", icon: "ImageIcon" },
                                    }),
                                    _vm._v(" Selecionar imagem "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.preview
                              ? [
                                  _c("b-img", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      for: "image",
                                      src: _vm.preview,
                                      fluid: "",
                                    },
                                  }),
                                ]
                              : !_vm.preview && _vm.image_url
                              ? [
                                  _c("b-img", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      for: "image",
                                      src: _vm.image_url,
                                      fluid: "",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [_vm._v(" Legenda ")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.image_caption,
                            expression: "item.image_caption",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.image_caption },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "image_caption",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Publicação" } },
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Criado "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("flat-pickr", {
                              staticClass: "form-control",
                              attrs: {
                                config: {
                                  enableTime: true,
                                  altInput: true,
                                  dateFormat: "Y-m-d H:m",
                                  altFormat: "d/m/Y H:m",
                                  locale: "pt",
                                },
                              },
                              model: {
                                value: _vm.item.created_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "created_at", $$v)
                                },
                                expression: "item.created_at",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Atualizado "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c("flat-pickr", {
                              staticClass: "form-control",
                              attrs: {
                                config: {
                                  enableTime: true,
                                  altInput: true,
                                  dateFormat: "Y-m-d H:m",
                                  altFormat: "d/m/Y H:m",
                                  locale: "pt",
                                },
                              },
                              model: {
                                value: _vm.item.updated_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "updated_at", $$v)
                                },
                                expression: "item.updated_at",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Opções" } },
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Jornalista "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group mb-2" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.item.author.$error,
                                },
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o nome",
                                  options: _vm.optionsAuthors,
                                },
                                on: { search: _vm.fetchAuthors },
                                model: {
                                  value: _vm.item.author,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "author", $$v)
                                  },
                                  expression: "item.author",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Área(s) "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group mb-2" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.item.areas.$error,
                                },
                                attrs: {
                                  multiple: "",
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsAreas,
                                },
                                on: { search: _vm.fetchAreas },
                                model: {
                                  value: _vm.item.areas,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "areas", $$v)
                                  },
                                  expression: "item.areas",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "custom-control-primary",
                                model: {
                                  value: _vm.item.display_site,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "display_site", $$v)
                                  },
                                  expression: "item.display_site",
                                },
                              },
                              [
                                _vm._v(" Exibir na pág. inicial do "),
                                _c("u", [_vm._v("site")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "custom-control-primary",
                                model: {
                                  value: _vm.item.display_blog_home,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "display_blog_home", $$v)
                                  },
                                  expression: "item.display_blog_home",
                                },
                              },
                              [
                                _vm._v(" Exibir na pág. inicial do "),
                                _c("u", [_vm._v("blog")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Tags" } },
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12 col-12" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Tag(s) "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c("b-form-tags", {
                              staticClass: "form-control",
                              attrs: {
                                separator: ",;",
                                "no-add-on-enter": "",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.item.tags,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "tags", $$v)
                                },
                                expression: "item.tags",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "form-row justify-content-right" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-12" },
                  [
                    _c("ButtonsActionsFooter", {
                      attrs: {
                        variant: "success",
                        submited: _vm.submited,
                        text: "Salvar",
                        subtext: "Aguarde...",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }